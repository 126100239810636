const Contact = () => {
    return (
      <div style={{margin:"25px"}}>
      <h2 class="mb-4 text-1xl font-extrabold text-gray-900 dark:text-white md:text-1xl lg:text-2xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Contact Us!</span></h2>
      <p>
         MARVY IT SERVICES can provide information on our full suite of solutions and services.
      </p>
      <p><a href="mailto:hr@marvyitservices.com" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">Email Us</a> or Call at 617-860-7304</p>
      </div>
    );
  };
  
  export default Contact;