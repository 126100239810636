import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Outlet, Link } from "react-router-dom";

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  const navItems = [
    // { id: 'home', text: 'Home' },
    { id: 'learnmore', text: 'Learn More' },
    { id: 'careers', text: 'Careers' },
    { id: 'contact', text: 'Contact' },
  ];

  return (
    <>
      <div className='bg-[#ADD8E6] shadow-lg flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-[#000000]'>
        {/* <a
          class="mb-4 me-2 mt-3 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-0 lg:mt-0"
          href="#">
          <img style={{height:"70px", width:"500px"}}
            src= {require('../images/marvy-logo.png')}
            alt=""
             />
        </a> */}
        <h1 className='w-full text-3xl font-bold text-[#00008B]'>MARVY IT SERVICES</h1>

        {/* Desktop Navigation */}
        <ul className='hidden md:flex'>
          {navItems.map(item => (
            <li key={`${item.id}`} className='p-4 hover:bg-[#00df9a] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
              <Link to={`/${item.id}`}> {item.text}</Link>
            </li>
          ))}
        </ul>

        {/* Mobile Navigation Icon */}
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* Mobile Navigation Menu */}
        <ul
          className={
            nav
              ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#ADD8E6] ease-in-out duration-500'
              : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
          }
        >
          {/* Mobile Logo */}
          <h1 className='w-full text-1xl font-bold text-[#00008B] m-4'>MARVY IT SERVICES</h1>

          {/* Mobile Navigation Items */}
          {navItems.map(item => (
            <li
              key={`${item.id}`}
              className='p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer border-gray-600'
            >
              <Link to={`/${item.id}`}> {item.text}</Link>
            </li>
          ))}
        </ul>
      </div>
      <Outlet />
    </>
  );

};

export default Navbar;