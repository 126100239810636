import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Navbar from "./pages/Navbar";
import Learnmore from "./pages/Learnmore";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Insights from "./pages/Insights";



export default function App() {
  return (
    <BrowserRouter>
     
      <Navbar />
     
      <div className=" shadow-lg flex justify-between min-h-screen max-h-screen max-w-[1240px] mx-auto">
       
          <Routes>
          <Route index  element={<Learnmore />} />
          <Route path="learnmore" element={<Learnmore />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<Contact />} />
          </Routes>
       
      </div>


    </BrowserRouter>
  );
}

